<template>
  <header class="header-area">
    <div class="container">
      <figure class="logo" @click="gotoHome">
        <img src="/assets/img/app/logo-desktop.png" alt="Bonauf">
      </figure>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderDesktop',
  methods: {
    gotoHome() {
      this.$router.push('/');
    },
  }
};
</script>

<style lang="scss" scoped>
  .header-area {
    max-width: 100%;
    background: rgb(6,149,209);
    background: linear-gradient(0deg, rgba(6,149,209,1) 0%, rgba(71,185,233,1) 100%);
    box-sizing: border-box;
    padding: 15px 0 15px;
    border-radius: 0px 0px 30px 30px;
    position: relative;
    z-index: 2;

    .logo {
      margin: 0px;
      margin-right: auto;
      max-width: 270px;
      cursor: pointer;

      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
</style>
